@import "../../global.scss";

.portfolioList{

  font-size: 16px;
  margin-right: 50px;
  padding: 9px;
  border-radius: 10px;
  cursor: pointer;

  transition: all 0.5s ease;

  @include mobile{
    //margin-right: 0;
    margin: 0 10px;
    //align-items: center;
  }

  &.active{
    background-color: $mainColor;
    color: $secondaryColor;
  }
}