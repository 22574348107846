@import "../../global.scss";

.menu{
  width: 300px;
  height: 100vh;
  background-color: $mainColor;

  position: fixed;
  top: 0;
  right: -300px;

  z-index: 2;

  //Display settings
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  //Transition settings
  transition: all 1s ease;

  &.active{
    right: 0;
  }

  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 30px;
    font-weight: 300;
    width: 60%;

    color: $secondaryColor;

    li{
      margin-bottom: 25px;

      a{
        font-size: inherit;
        color: inherit;
        text-decoration: none;
      }

      &:hover{
        font-weight: 500;
      }
    }
  }

  h4{
    color: $secondaryColor;
    font-weight: 200;
    font-size: 12px;
    //margin-top: 150px;


    position: absolute;
    bottom: 40px;
  }
}