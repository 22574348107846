@import "../../global.scss";

.topbar{
  //Sizing
  width: 100%;
  height: 70px;

  //Coloring
  background-color: $secondaryColor;
  color: $mainColor;

  //Positioning
  position: fixed;
  top: 0;
  z-index: 3; //Places on top of the other components all the time

  //Transition settings
  transition: all 1s ease;

  //A wrapper for padding and whatnot
  .wrapper{
    padding: 10px 30px; //simple padding
    display: flex; //flexbox
    align-items: center;
    justify-content: space-between; //maximizing the space between them, makes left/right areas

    .left{

      display: flex;
      align-items: center;

      .logo{
        margin-right: 40px;

        //font settings
        font-size: 40px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
      }

      .itemContainer{
        display: flex;
        align-items: center;

        margin-left: 20px;

        @include mobile{
          display: none;
        }

        .icon{
          font-size: 20px;
          margin-right: 5px;
        }

        span{
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    .right{

      .hamburger{
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        cursor: pointer;

        span{
          width: 100%;
          height: 3px;
          background-color: $mainColor;

          //transform-origin: left;
          transform-origin: left;
          transition: all 1.2s ease;
        }
      }
    }
  }

  &.active{ //Picking out when the topbar also has the active name

    //Overwriting color setting
    background-color: $mainColor;
    color: $secondaryColor;

    //Entering the hamburger icon to edit
    .hamburger{
      padding: 2px;
      span{
        &:first-child{ //Picking out the first child like so.
          background-color: $secondaryColor;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
          transform: scale(0, 1);
        }
        &:last-child{
          background-color: $secondaryColor;
          transform: rotate(-45deg);
          //transform: translate(1);
        }
      }
    }
  }
}