@import "../../global.scss";

.intro{
  //background-color: lightpink;
  background-color: $secondaryColor;

  display: flex;

  @include mobile{
    flex-direction: column;
    align-items: center;
  }


  .left{
    flex: 0.5;
    //background-color: orange; //for testing

    overflow: hidden;

    .imgContainer{
      width: 1100px;
      height: 1100px;

      //background-color: crimson;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      float: right;

      @include mobile{
        align-items: flex-start;
      }

      img{
        height: 100%;
        object-fit: cover;
        object-position: left;

        @include mobile{
          height: 50%;
        }
      }
    }
  }

  .right{
    flex: 0.5;
    //background-color: red; //for testing
    position: relative;

    .wrapper{
      width: 100%;
      height: 100%;
      //background-color: red;

      padding-left: 50px;

      display: flex;
      flex-direction: column;
      justify-content: center;



      @include mobile{
        padding-left: 0;
        align-items: center;

      }

      h1{
        font-size: 65px;
        margin: 10px 0px;

        @include mobile{
          font-size: 45px;
        }
      }
      h2{
        font-size: 35px;
        @include mobile{
          font-size: 30px;
        }
      }
      h3{
        font-size: 30px;

        @include mobile{
          font-size: 23px;
        }

        span{
          font-size: inherit;
          color: crimson
        }

        .ityped-cursor {
          font-size: 2.2rem;
          opacity: 1;
          -webkit-animation: blink 0.3s infinite;
          -moz-animation: blink 0.3s infinite;
          animation: blink 0.3s infinite;
          animation-direction: alternate;
        }
        @keyframes blink {
          50%{
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @-webkit-keyframes blink {
          50%{
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @-moz-keyframes blink {
          50%{
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }

      }
      a{
        position: absolute;
        bottom: 15px;
        left: 40%;


        .icon{
          font-size: 65px;
          text-decoration: none;
          color: $mainColor;

          @include mobile{
            font-size: 45px;
          }

          animation: arrowBlink 2s infinite;
        }

        @keyframes arrowBlink {
          100%{
            opacity: 0;
          }
        }
      }
    }
  }
}